var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-sm-2" },
        [_c("user-social-selfies", { attrs: { brandId: _vm.brandId } })],
        1
      ),
      _c(
        "div",
        { staticClass: "col-sm-10" },
        [_c("pickls-insights", { attrs: { brandId: _vm.brandId } })],
        1
      ),
      _c(
        "div",
        { staticClass: "col-sm-6" },
        [_c("store-insights", { attrs: { brandId: _vm.brandId } })],
        1
      ),
      _c(
        "div",
        { staticClass: "col-sm-6" },
        [_c("state-insights", { attrs: { brandId: _vm.brandId } })],
        1
      ),
      _c(
        "div",
        { staticClass: "col-sm-12", staticStyle: { "margin-top": "20px" } },
        [_c("IssuesInsights", { attrs: { brandId: _vm.brandId } })],
        1
      ),
      _c(
        "div",
        { staticClass: "col-sm-8", staticStyle: { "margin-top": "20px" } },
        [_c("shopping-insights", { attrs: { brandId: _vm.brandId } })],
        1
      ),
      _c(
        "div",
        { staticClass: "col-sm-4", staticStyle: { "margin-top": "20px" } },
        [_c("product-insights", { attrs: { brandId: _vm.brandId } })],
        1
      ),
      _c(
        "div",
        { staticClass: "col-sm-7", staticStyle: { "margin-top": "20px" } },
        [_c("survey-insights", { attrs: { brandId: _vm.brandId } })],
        1
      ),
      _c(
        "div",
        { staticClass: "col-sm-5", staticStyle: { "margin-top": "20px" } },
        [_c("pickl-type-insights", { attrs: { brandId: _vm.brandId } })],
        1
      ),
      _c(
        "div",
        { staticClass: "col-sm-12", staticStyle: { "margin-top": "20px" } },
        [_c("AverageCompletionTimes", { attrs: { brandId: _vm.brandId } })],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }